





































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ParkingPermitForm from "@/components/forms/ParkingPermitForm.vue";
import { ParkingPermit } from "@/api/models";
import api from "@/api/api";

export default Vue.extend({
  name: "ParkingPermits",

  components: {
    ParkingPermitForm,
  },

  data: () => ({
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Parking Permits",
        disabled: true,
      },
    ] as Record<string, string | any>,

    lotId: 0,
    isLoading: false,
    showParkingPermitForm: false,
    showParkingPermitDeleteDialog: false,

    parkingPermits: {
      headers: [
        // { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Icon Symbol", value: "icon_symbol", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Is Standard Permit", value: "is_standard", sortable: false },
        { text: "Is Restricted Permit", value: "is_restricted" },
        { text: "Last Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      data: [] as Array<ParkingPermit>,
      selected: null as ParkingPermit | null,
    },
  }),

  async mounted() {
    this.lotId = Number(this.$route.params.lotId);
    this.getLotData(this.lotId);
    await this.initData();
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lotId: number | null) {
      if (lotId) {
        let parkingLotData = await api.getParkingLot(lotId);
        this.initCurrentParkingLotData(parkingLotData);

        if (parkingLotData) {
          this.breadcrumbItems[0].text = parkingLotData.name;
          this.breadcrumbItems[0].to = { name: "LotDashboard" };
        }
      }
    },

    async initData() {
      try {
        this.isLoading = true;
        const parkingPermitsData = await api.getAllParkingPermits(this.lotId);
        if (parkingPermitsData) {
          this.parkingPermits.data = parkingPermitsData;
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load parking permits. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },

    openParkingPermitDetails(parkingPermit: ParkingPermit) {
      if (parkingPermit.is_standard) {
        return; // Standard permits shouldn't open popup on click.
      }
      console.log("Opening parkingPermit details", parkingPermit);
      this.parkingPermits.selected = parkingPermit;
      this.showParkingPermitForm = true;
    },

    closeForm() {
      this.showParkingPermitForm = false;
      this.parkingPermits.selected = null;
    },

    openParkingPermitDeleteDialog(parkingPermit: ParkingPermit) {
      this.parkingPermits.selected = parkingPermit;
      this.showParkingPermitDeleteDialog = true;
    },

    closeParkingPermitDeleteDialog() {
      this.parkingPermits.selected = null;
      this.showParkingPermitDeleteDialog = false;
    },

    async deleteSelectedParkingPermit() {
      if (!this.parkingPermits.selected) {
        return;
      }
      this.isLoading = true;
      let deleted = await api.deleteParkingPermit(
        this.lotId,
        this.parkingPermits.selected.id
      );
      this.isLoading = false;
      this.closeParkingPermitDeleteDialog();

      if (deleted) {
        this.initData(); // Refresh data
      } else {
        this.$dialog.message.error(
          "Error, delete unsuccessful. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
  },
});
